
import {
    defineComponent, watch, ref, Ref,
    PropType,
    computed, reactive
} from 'vue';
import { isCNServer, isJapan, isRuServer } from '@/util/location';
import { community, communityType } from '@/data';
import { format } from '@/methods/date';
import { ChangeWordList } from '@/methods/setText';
import { SearchKey } from '@/components/common/list';
import { DoorLogSearchData } from './all.type';
import './index.less';

export default defineComponent({
    props: {
        searchData: {
            type: Object as PropType<DoorLogSearchData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const logsType: Array<{ label: string; value: string }> = reactive(
            [
                {
                    label: WordList.SelectAll,
                    value: 'all'
                },
                {
                    label: WordList.ProperAllTextCall,
                    value: 'call'
                },
                {
                    label: WordList.ProperAllTextDoorRelease,
                    value: 'doorRelease'
                }
            ]
        );
        // 初始化搜索栏数据
        const formData: Ref<DoorLogSearchData> = ref({
            buildingId: '',
            doorLogType: '',
            startTime: '',
            endTime: '',
            searchKey: 'device',
            searchValue: '',
            action: '',
            key: ''
        });
        watch(computed(() => props.searchData), () => {
            formData.value = { ...props.searchData };
        }, {
            immediate: true
        });

        const communityBr: Ref<communityType.CommunityBrType> = ref({});
        community.getCommunityBr((res: communityType.CommunityBrType) => {
            communityBr.value = res;
        });

        const actionSelectList = ref([
            { name: '', label: WordList.SelectAll },
            { name: 'pin', label: WordList.ProperAllTextPIN },
            { name: 'rfCard', label: WordList.DeliveryRfCard },
            { name: 'face', label: WordList.ProperAllTextPhotos },
            { name: 'bluetooth', label: WordList.Bluetooth },
            { name: 'tempKey', label: WordList.ProperAllTextTempKey },
            { name: 'indoorMonitor', label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor },
            { name: 'smartPlus', label: WordList.ProperAllTextSmartplus },
            { name: 'guardPhone', label: WordList.SelectManagementPhone },
            { name: 'booking', label: WordList.AmenityReservation }
        ]);

        // 日本云新增选项（需迁移）
        if (isJapan()) {
            actionSelectList.value.push({ name: 'Delivery', label: WordList.DeliverySystemVerification });
        }
        const getSearchKeyList = (projectType: ProjectType | false = false) => {
            if (community.IsEncryptPin.value === '1') {
                if (projectType === 'office') {
                    return [
                        { name: 'device', label: WordList.DeviceLocation },
                        { name: 'initiator', label: WordList.CaptureListTanleInitiator }
                    ];
                }

                // 旧社区不变，新社区移除 RfCard
                return community.isNew.value ? [
                    { name: 'Device', label: WordList.DeviceLocation },
                    { name: 'Initiator', label: WordList.CaptureListTanleInitiator },
                    { name: 'Apt', label: WordList.RDeviceSearchLabelRoomName }
                ] : [
                    { name: 'Device', label: WordList.DeviceLocation },
                    { name: 'Initiator', label: WordList.CaptureListTanleInitiator },
                    { name: 'Apt', label: WordList.RDeviceSearchLabelRoomName },
                    { name: 'RfCard', label: WordList.DeliveryRfCard }
                ];
            }
            if (projectType === 'office') {
                return [
                    { name: 'device', label: WordList.DeviceLocation },
                    { name: 'initiator', label: WordList.CaptureListTanleInitiator }
                ];
            }

            // 旧社区不变，新社区移除 RfCard 和 Pin
            return community.isNew.value ? [
                { name: 'Device', label: WordList.DeviceLocation },
                { name: 'Initiator', label: WordList.CaptureListTanleInitiator },
                { name: 'Apt', label: WordList.RDeviceSearchLabelRoomName }
            ] : [
                { name: 'Device', label: WordList.DeviceLocation },
                { name: 'Initiator', label: WordList.CaptureListTanleInitiator },
                { name: 'Apt', label: WordList.RDeviceSearchLabelRoomName },
                { name: 'RfCard', label: WordList.DeliveryRfCard },
                { name: 'Pin', label: WordList.ProperAllTextPIN }
            ];
        };
        // 办公doorlog下拉框删除apt选项
        const searchKeyList: Ref< SearchKey > = ref([]);
        watch([community.ProjectType, community.IsEncryptPin], () => {
            if (community.ProjectType.value === 'community' && community.isNew.value) {
                logsType.push(
                    {
                        label: WordList.DoorClose,
                        value: 'doorClose'
                    }
                );
            }
            searchKeyList.value = getSearchKeyList(community.ProjectType.value);
        }, {
            immediate: true
        });

        // 返回搜索条件
        const updateToList = () => {
            const param = { ...formData.value };
            param.startTime = format(param.startTime, 'YYYY-MM-DD HH:mm:ss');
            param.endTime = format(param.endTime, 'YYYY-MM-DD HH:mm:ss');
            emit('updateToList', param);
        };

        return {
            isCNServer,
            isRuServer,
            logsType,
            formData,
            isNew: community.isNew,
            communityBr,
            searchKeyList,
            actionSelectList,
            updateToList,
            emit,
            ChangeWordList
        };
    }
});
